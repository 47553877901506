/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import NewCateringRequestPage from "admins/containers/NewCateringRequestPage/Loadable"
import React from "react"
import { Switch, Route, withRouter, Redirect } from "react-router-dom"
import CssBaseline from "@material-ui/core/CssBaseline"
import styled from "styled-components"
import { connect } from "react-redux"
import { compose } from "redux"
import HomePage from "admins/containers/HomePage/Loadable"
import BuildersPage from "admins/containers/BuildersPage/Loadable"
import NotFoundPage from "admins/containers/NotFoundPage/Loadable"
import CateringCategoriesPage from "admins/containers/CateringCategoriesPage/Loadable"
import FoodTypePagesPage from "admins/containers/FoodTypePagesPage/Loadable"
import FoodTypePagePage from "admins/containers/FoodTypePagePage/Loadable"
import CateringCategoryPage from "admins/containers/CateringCategoryPage/Loadable"
import FoodTypeCategoriesPage from "admins/containers/FoodTypeCategoriesPage/Loadable"
import OrderPartialRefundsListPage from "admins/containers/OrderPartialRefundsListPage/Loadable"
import OrderPartialRefundPage from "admins/containers/OrderPartialRefundPage/Loadable"
import NewFoodTypeCategoryPage from "admins/containers/NewFoodTypeCategoryPage/Loadable"
import EditFoodTypeCategoryPage from "admins/containers/EditFoodTypeCategoryPage/Loadable"
import OrganizerPageHintsPage from "admins/containers/OrganizerPageHintsPage/Loadable"
import NewOrganizerPageHintPage from "admins/containers/NewOrganizerPageHintPage/Loadable"
import EditOrganizerPageHintPage from "admins/containers/EditOrganizerPageHintPage/Loadable"
import CustomersPage from "admins/containers/CustomersPage/Loadable"
import FeedPage from "admins/containers/FeedPage/Loadable"
import UiDemoPage from "admins/containers/UiDemoPage/Loadable"
import CustomerPage from "admins/containers/CustomerPage/Loadable"
import EditCustomerPage from "admins/containers/EditCustomerPage/Loadable"
import CustomerListPage from "admins/containers/CustomerListPage/Loadable"
import EditRestaurantPage from "admins/containers/EditRestaurantPage/Loadable"
import RestaurantPage from "admins/containers/RestaurantPage/Loadable"
import NewRestaurantPage from "admins/containers/NewRestaurantPage/Loadable"
import NewCustomerPage from "admins/containers/NewCustomerPage/Loadable"
import BulkCustomerCreatePage from "admins/containers/BulkCustomerCreatePage/Loadable"
import LocationsPage from "admins/containers/LocationsPage/Loadable"
import LocationListPage from "admins/containers/LocationListPage/Loadable"
import LocationCalendarPage from "admins/containers/LocationCalendarPage/Loadable"
import EditLocationPage from "admins/containers/EditLocationPage/Loadable"
import LocationAutoCoverPage from "admins/containers/LocationAutoCoverPage/Loadable"
import LocationPage from "admins/containers/LocationPage/Loadable"
import LoginPage from "admins/containers/LoginPage/Loadable"
import LotsPage from "admins/containers/LotsPage/Loadable"
import CouponsPage from "admins/containers/CouponsPage/Loadable"
import NewCouponPage from "admins/containers/NewCouponPage/Loadable"
import EditCouponPage from "admins/containers/EditCouponPage/Loadable"
import EditLotPage from "admins/containers/EditLotPage/Loadable"
import AppConfigPage from "admins/containers/AppConfigPage/Loadable"
import OrganizationsPage from "admins/containers/OrganizationsPage/Loadable"
import OrganizationPage from "admins/containers/OrganizationPage/Loadable"
import OrganizerAccountPage from "admins/containers/OrganizerAccountPage/Loadable"
import OrderPage from "admins/containers/OrderPage/Loadable"
import CorporatePage from "admins/containers/CorporatePage/Loadable"
import ClientCompanyPage from "admins/containers/ClientCompanyPage/Loadable"
import NewClientCompanyPage from "admins/containers/NewClientCompanyPage/Loadable"
import NewCorporateUserPage from "admins/containers/NewCorporateUserPage/Loadable"
import MenusPage from "admins/containers/MenusPage/Loadable"
import NewMenuPage from "admins/containers/NewMenuPage/Loadable"
import MenuPage from "admins/containers/MenuPage/Loadable"
import NewFoodItemPage from "admins/containers/NewFoodItemPage/Loadable"
import FoodItemPage from "admins/containers/FoodItemPage/Loadable"
import NewQuestionPage from "admins/containers/NewQuestionPage/Loadable"
import LotPage from "admins/containers/LotPage/Loadable"
import EventPage from "admins/containers/EventPage/Loadable"
import QuestionPage from "admins/containers/QuestionPage/Loadable"
import RestaurantsPage from "admins/containers/RestaurantsPage/Loadable"
import MenuTypesPage from "admins/containers/MenuTypesPage/Loadable"
import MenuTypePage from "admins/containers/MenuTypePage/Loadable"
import TruckReviewsPage from "admins/containers/TruckReviewsPage/Loadable"
import TruckReviewDetailsPage from "admins/containers/TruckReviewDetailsPage/Loadable"
import AccountDeletionRequestsPage from "admins/containers/AccountDeletionRequestsPage/Loadable"
import ErrorBoundary from "common/components/ErrorBoundary/index"
import Navbar from "admins/components/Navbar"
import NotificationStack from "admins/components/NotificationStack"
import BlockUIConnected from "admins/components/BlockUIConnected"
import LotGroupsPage from "admins/containers/LotGroupsPage/Loadable"
import NewLotGroupPage from "admins/containers/NewLotGroupPage/Loadable"
import LotGroupPage from "admins/containers/LotGroupPage/Loadable"
import LocationOrdersPage from "admins/containers/LocationOrdersPage/Loadable"
import UploadedAssetsPage from "admins/containers/UploadedAssetsPage/Loadable"
import withUser from "admins/queries/withUser"
import PageLoader from "common/components/PageLoader"
import NewOrganizationPage from "admins/containers/NewOrganizationPage/Loadable"
import MarketsPage from "admins/containers/MarketsPage/Loadable"
import EditMarketPage from "admins/containers/EditMarketPage/Loadable"
import MarketTopTrucksPage from "admins/containers/MarketTopTrucksPage/Loadable"
import MarketFacebookPage from "admins/containers/MarketFacebookPage/Loadable"
import NewMarketPage from "admins/containers/NewMarketPage/Loadable"
import ShiftSalesPage from "admins/containers/ShiftSalesPage/Loadable"
import TruckNotificationsPage from "admins/containers/TruckNotificationsPage/Loadable"
import TriggerConfigPage from "admins/containers/TriggerConfigPage/Loadable"
import EventsRequestsPage from "admins/containers/EventsRequestsPage/Loadable"
import EventsRequestPage from "admins/containers/EventsRequestPage/Loadable"
import CateringCustomRequestsPage from "admins/containers/CateringCustomRequestsPage/Loadable"
import CateringCustomRequestPage from "admins/containers/CateringCustomRequestPage/Loadable"
import CateringRequestsPage from "admins/containers/CateringRequestsPage/Loadable"
import SupportConversationsPage from "admins/containers/SupportConversationsPage/Loadable"
import ChatConversationsPage from "admins/containers/ChatConversationsPage/Loadable"
import ChatConversationPage from "admins/containers/ChatConversationPage/Loadable"
import CateringRequestPage from "admins/containers/CateringRequestPage/Loadable"
import CateringRequestOrdersPage from "admins/containers/CateringRequestOrdersPage/Loadable"
import LocationStatsPage from "admins/containers/LocationStatsPage/Loadable"
import BlogPostsPage from "admins/containers/BlogPostsPage/Loadable"
import BlogPostCreatePage from "admins/containers/BlogPostCreatePage/Loadable"
import BlogPostUpdatePage from "admins/containers/BlogPostUpdatePage/Loadable"
import TruckPage from "admins/containers/TruckPage/Loadable"
import TruckEditPage from "admins/containers/TruckEditPage/Loadable"
import ForgotPasswordPage from "admins/containers/ForgotPasswordPage/Loadable"
import ResetPasswordPage from "admins/containers/ResetPasswordPage/Loadable"
import ReplyTemplatesListPage from "admins/containers/ReplyTemplatesListPage/Loadable"
import ReplyTemplateNewPage from "admins/containers/ReplyTemplateNewPage/Loadable"
import ReplyTemplateEditPage from "admins/containers/ReplyTemplateEditPage/Loadable"

const OuterBody = styled.main``

class App extends React.PureComponent {
  render() {
    const { userLoading, user } = this.props
    if (userLoading) return <PageLoader />
    const routes = user ? (
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/feed" component={FeedPage} />
        <Route exact path="/restaurants" component={RestaurantsPage} />
        <Route exact path="/trucks-review" component={TruckReviewsPage} />
        <Route
          exact
          path="/account-deletion-requests"
          component={AccountDeletionRequestsPage}
        />
        <Route
          exact
          path="/trucks-review/:id"
          component={TruckReviewDetailsPage}
        />
        <Route exact path="/refunds" component={OrderPartialRefundsListPage} />
        <Route
          exact
          path="/refunds/:refundId"
          component={OrderPartialRefundPage}
        />
        <Route exact path="/shift-sales" component={ShiftSalesPage} />
        <Route exact path="/customers" component={CustomersPage} />
        <Route exact path="/customers/list" component={CustomerListPage} />
        <Route exact path="/customers/new" component={NewCustomerPage} />
        <Route
          exact
          path="/customers/bulk_create"
          component={BulkCustomerCreatePage}
        />
        <Route exact path="/customers/:customerId" component={CustomerPage} />
        <Route
          exact
          path="/customers/:customerId/edit"
          component={EditCustomerPage}
        />
        <Route
          exact
          path="/customers/:customerId/orders/:orderId"
          component={OrderPage}
        />
        <Route exact path="/trucks/:id/edit" component={TruckEditPage} />
        <Route exact path="/trucks/:id" component={TruckPage} />
        <Route exact path="/restaurants/new" component={NewRestaurantPage} />
        <Route
          exact
          path="/restaurants/:id/edit"
          component={EditRestaurantPage}
        />
        <Route
          exact
          path="/restaurants/:restaurantId/menus"
          component={MenusPage}
        />
        <Route
          exact
          path="/restaurants/:restaurantId/menus/new"
          component={NewMenuPage}
        />
        <Route
          exact
          path="/restaurants/:restaurantId/menus/:menuId"
          component={MenuPage}
        />
        <Route
          exact
          path="/restaurants/:restaurantId/menus/:menuId/items/new"
          component={NewFoodItemPage}
        />
        <Route
          exact
          path="/restaurants/:restaurantId/menus/:menuId/items/:itemId"
          component={FoodItemPage}
        />
        <Route
          exact
          path="/restaurants/:restaurantId/menus/:menuId/items/:itemId/questions/new"
          component={NewQuestionPage}
        />
        <Route
          exact
          path="/restaurants/:restaurantId/menus/:menuId/items/:itemId/questions/:questionId/edit"
          component={QuestionPage}
        />
        <Route
          exact
          path="/restaurants/:restaurantId/locations"
          component={LocationListPage}
        />
        <Route
          exact
          path="/restaurants/:restaurantId/locations/:locationId/orders"
          component={LocationOrdersPage}
        />
        <Route
          exact
          path="/locations/:locationId/orders"
          component={LocationOrdersPage}
        />
        <Route exact path="/restaurants/:id" component={RestaurantPage} />
        <Route
          exact
          path="/locations/:id/stats"
          component={LocationStatsPage}
        />
        <Route exact path="/builders/menu-types" component={MenuTypesPage} />
        <Route exact path="/builders/menu-types/:id" component={MenuTypePage} />
        <Route exact path="/builders/blog-posts" component={BlogPostsPage} />
        <Route
          exact
          path="/builders/blog-posts/new"
          component={BlogPostCreatePage}
        />
        <Route
          exact
          path="/builders/blog-posts/:id"
          component={BlogPostUpdatePage}
        />
        <Route
          exact
          path="/builders/food-type-pages"
          component={FoodTypePagesPage}
        />
        <Route
          exact
          path="/builders/food-type-pages/:id"
          component={FoodTypePagePage}
        />
        <Route
          exact
          path="/builders/catering-categories"
          component={CateringCategoriesPage}
        />
        <Route
          exact
          path="/builders/catering-categories/:id"
          component={CateringCategoryPage}
        />
        <Route
          exact
          path="/builders/food-types"
          component={FoodTypeCategoriesPage}
        />
        <Route
          exact
          path="/builders/food-types/new"
          component={NewFoodTypeCategoryPage}
        />
        <Route
          exact
          path="/builders/food-types/:id"
          component={EditFoodTypeCategoryPage}
        />
        <Route
          exact
          path="/builders/organizer-page-hints"
          component={OrganizerPageHintsPage}
        />
        <Route
          exact
          path="/builders/organizer-page-hints/new"
          component={NewOrganizerPageHintPage}
        />
        <Route
          exact
          path="/builders/organizer-page-hints/:id"
          component={EditOrganizerPageHintPage}
        />
        <Route exact path="/builders/lots" component={LotsPage} />
        <Route exact path="/builders/lots/:id/edit" component={EditLotPage} />
        <Route exact path="/builders/lots/:id" component={LotPage} />
        <Route
          exact
          path="/builders/lots/:lotId/events/:eventId"
          component={EventPage}
        />
        <Route exact path="/builders/markets" component={MarketsPage} />
        <Route exact path="/builders/markets/new" component={NewMarketPage} />
        <Route
          exact
          path="/builders/markets/:id/top-trucks"
          component={MarketTopTrucksPage}
        />
        <Route
          exact
          path="/builders/markets/:id/facebook-page"
          component={MarketFacebookPage}
        />
        <Route exact path="/builders/markets/:id" component={EditMarketPage} />
        <Route exact path="/builders/coupons" component={CouponsPage} />
        <Route exact path="/builders/coupons/new" component={NewCouponPage} />
        <Route exact path="/builders/coupons/:id" component={EditCouponPage} />
        <Route exact path="/builders/app-config" component={AppConfigPage} />
        <Route exact path="/builders/lot-groups" component={LotGroupsPage} />
        <Route
          exact
          path="/builders/lot-groups/new"
          component={NewLotGroupPage}
        />
        <Route exact path="/builders/lot-groups/:id" component={LotGroupPage} />
        <Route
          exact
          path="/builders/uploaded-assets"
          component={UploadedAssetsPage}
        />
        <Route
          exact
          path="/builders/credit-trigger-configs"
          component={TriggerConfigPage}
        />
        <Route
          exact
          path="/builders/notifications"
          component={TruckNotificationsPage}
        />
        <Route
          exact
          path="/builders/reply-templates"
          component={ReplyTemplatesListPage}
        />
        <Route
          exact
          path="/builders/reply-templates/new"
          component={ReplyTemplateNewPage}
        />
        <Route
          exact
          path="/builders/reply-templates/:id"
          component={ReplyTemplateEditPage}
        />
        <Route exact path="/builders" component={BuildersPage} />
        <Route exact path="/locations" component={LocationsPage} />
        <Route exact path="/organizations" component={OrganizationsPage} />
        <Route
          exact
          path="/organizations/new"
          component={NewOrganizationPage}
        />
        <Route exact path="/organizations/:id" component={OrganizationPage} />
        <Route
          exact
          path="/organizations/:id/users/:organizerId"
          component={OrganizerAccountPage}
        />
        <Route
          exact
          path="/locations/calendar"
          component={LocationCalendarPage}
        />
        <Route exact path="/locations/calendar/:id" component={LocationPage} />
        <Route
          exact
          path="/locations/calendar/:id/edit"
          component={EditLocationPage}
        />
        <Route exact path="/locations/:id/edit" component={EditLocationPage} />
        <Route
          exact
          path="/locations/:id/auto-cover"
          component={LocationAutoCoverPage}
        />
        <Route exact path="/locations/:id" component={LocationPage} />
        <Route exact path="/orders/:orderId" component={OrderPage} />
        <Route exact path="/corporate" component={CorporatePage} />
        <Route
          exact
          path="/corporate/client-companies/new"
          component={NewClientCompanyPage}
        />
        <Route
          exact
          path="/corporate/client-companies/:id"
          component={ClientCompanyPage}
        />
        <Route
          exact
          path="/corporate/client-companies/:id/users/new"
          component={NewCorporateUserPage}
        />
        <Route exact path="/events-requests" component={EventsRequestsPage} />
        <Route
          exact
          path="/events-requests/:id"
          component={EventsRequestPage}
        />
        <Route
          exact
          path="/support-conversations"
          component={SupportConversationsPage}
        />
        <Route
          exact
          path="/chat-conversations"
          component={ChatConversationsPage}
        />
        <Route
          exact
          path="/chat-conversations/:id"
          component={ChatConversationPage}
        />
        <Route
          exact
          path="/custom-event-requests"
          component={CateringCustomRequestsPage}
        />
        <Route
          exact
          path="/custom-event-requests/:id"
          component={CateringCustomRequestPage}
        />
        <Route
          exact
          path="/catering-requests"
          component={CateringRequestsPage}
        />
        <Route
          exact
          path="/catering-requests/new"
          component={NewCateringRequestPage}
        />
        <Route
          exact
          path="/catering-requests/:id/orders"
          component={CateringRequestOrdersPage}
        />
        <Route
          exact
          path="/catering-requests/:id"
          component={CateringRequestPage}
        />
        <Route exact path="/ui" component={UiDemoPage} />
        <Route exact path="/forgot-password" component={ForgotPasswordPage} />
        <Route exact path="/reset-password" component={ResetPasswordPage} />
        <Route component={NotFoundPage} />
      </Switch>
    ) : (
      <Switch>
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/forgot-password" component={ForgotPasswordPage} />
        <Route exact path="/reset-password" component={ResetPasswordPage} />
        <Redirect to="/" path="/sign-in" />
        <Redirect to="/login" />
      </Switch>
    )
    return (
      <OuterBody>
        <CssBaseline />
        <Navbar>
          <ErrorBoundary message="Error Displaying the page. Try reloading the browser.">
            {routes}
          </ErrorBoundary>
        </Navbar>
        <BlockUIConnected />
        <NotificationStack />
      </OuterBody>
    )
  }
}

const withConnect = connect()

export default compose(withRouter, withConnect, withUser)(App)
