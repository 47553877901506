import { createGlobalStyle } from "styled-components"
import "common/vendor/tailwind.scss" // eslint-disable-line
import "common/vendor/bs_tables.css" // eslint-disable-line
import "common/vendor/grid.css" // eslint-disable-line
import "react-block-ui/dist/style.css" // eslint-disable-line
import "react-big-calendar/lib/css/react-big-calendar.css" // eslint-disable-line

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Gotham A', 'Gotham B', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  #app {
    background-color: #fafafa;
  }

  p,
  label {
    font-family: Georgia, Times, 'Times New Roman', serif;
    line-height: 1.5em;
  }
  
  .ReactModal__Body--open {
    overflow: hidden;
  }

  .notification-bar {
    z-index: 1400 !important;
  }

  .MuiFormHelperText-contained {
    margin-left: 0;
    margin-right: 0;
  }
  
  @media print {
    header, .breadcrumbs {
      display: none !important;
    }
    
    #page-main {
      height: 100%;
      margin-top: 0;
    }
    
    .no-print {
      display: none !important;
    }
  }
`

export default GlobalStyle
