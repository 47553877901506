import React, {
  ComponentType,
  lazy,
  LazyExoticComponent,
  Suspense
} from "react"

const lazyRetry = <T extends ComponentType<any>>(
  importComponent: () => Promise<{ default: T }>
): LazyExoticComponent<T> =>
  lazy<T>(async () => {
    const key = "loaded_" + importComponent.toString()
    const componentsRefreshedString = sessionStorage.getItem(key)
    const hasComponentRefreshed: boolean = componentsRefreshedString
      ? JSON.parse(componentsRefreshedString) || false
      : false

    try {
      const component = await importComponent()
      sessionStorage.removeItem(key)
      return component
    } catch (error) {
      if (!hasComponentRefreshed) {
        sessionStorage.setItem(key, JSON.stringify(true))
        location.reload()
      }

      throw error
    }
  })

function loadable<T>(
  importFunc: () => Promise<any>,
  { fallback = null }: { fallback?: React.ReactNode | null } = {
    fallback: null
  }
) {
  const LazyComponent = lazyRetry(importFunc)

  return function LoadableWrapper(props: any) {
    return (
      <Suspense fallback={fallback}>
        <LazyComponent {...props} />
      </Suspense>
    )
  }
}

export default loadable
